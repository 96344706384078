import { graphql } from 'gatsby'
import Link from '../components/link'
import Image from '../components/image'
import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'

import Carousel from '../components/carousel'
import CoverImage from '../components/cover-image'
import Promotions from '../components/promotions'
import MenuModal from '../components/menu-modal'
import { useIsMobile } from '../hooks/screen-size'
import styles from './intermission-bar.module.css'
import CenterTextSection from '../components/center-text-section'
import SEO from '../components/seo'

const barCarouselSlides = (coverImages, title, onPlay, onPause) =>
  (coverImages || []).map(i => {
    const imageSrc =
      i.image && i.image.childImageSharp ? i.image.childImageSharp.fluid : null

    return (
      <CoverImage
        className={styles.coverImage}
        videoUrl={i.youtube}
        image={imageSrc}
        title={title}
        onTrailerPlay={onPlay}
        onTrailerPause={onPause}
      />
    )
  })

export const NoSpoilersDiningTemplate = ({ page }) => {
  const {
    blurb,
    coverImages,
    promotions,
    subtitle,
    title,
    barFeature,
    menuFile,
  } = page.frontmatter
  const [isSlidePaused, setIsSlidePaused] = useState(false)
  const isMobile = useIsMobile()
  const onVideoPlay = useRef()
  const onVideoPause = useRef()
  onVideoPlay.current = () => {
    setIsSlidePaused(true)
  }

  onVideoPause.current = () => {
    setIsSlidePaused(false)
  }

  const slides = barCarouselSlides(
    coverImages,
    title,
    onVideoPlay.current,
    onVideoPause.current
  )

  return (
    <>
    <SEO 
      title={"Cinema bar | Craft beers | Cocktails | Gourmet Food"}
      description={"The Projector's newest outlet at Cineleisure in Orchard Road has a full service restaurant serving southeast asian soul food"}
    />
    <div className="container flex flex-col items-center py-5 lg:py-20">
      <div className={styles.header}>
        <div className={styles.subtitle}>{subtitle}</div>
        <div className={styles.title}>{title}</div>
      </div>
      {slides.length ? (
        <div className={styles.carouselWrapper}>
          <Carousel
            slides={slides}
            previewSlides={isMobile}
            isPaused={isSlidePaused}
          />
        </div>
      ) : null}
      <div className={styles.blurb}>{blurb}</div>
      <Promotions
        promotions={promotions.map(p => {
          if (p.showMenu) {
            p.modal = o => MenuModal(Object.assign(o || {}, { menuFile }))
          }
          return p
        })}
        alternateStyling={true}
        className={styles.promotion}
      />
      <div className={styles.barFeatureWrapper}>
        <CenterTextSection
          {...barFeature}
          callToAction={{
            linkURL: barFeature.linkURL,
            label: barFeature.callToAction,
          }}
        />
      </div>
    </div>
    </>
  )
}

const NoSpoilersDiningPage = ({ data }) => {
  return <NoSpoilersDiningTemplate {...data} />
}

NoSpoilersDiningPage.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.object,
  }),
}

export default NoSpoilersDiningPage

export const pageQuery = graphql`
  query NoSpoilersDiningPage {
    page: markdownRemark(fields: { slug: { eq: "/no-spoilers-dining/" } }) {
      frontmatter {
        title
        subtitle
        blurb
        coverImages {
          image {
            childImageSharp {
              fluid(maxWidth: 1180, maxHeight: 555, quality: 92) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          youtube
        }
        promotions {
          blurb
          callToAction
          linkURL
          coverImage {
            childImageSharp {
              fluid(maxWidth: 678, maxHeight: 453, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          showMenu
          title
        }
        barFeature {
          title
          bodyHtml
          image {
            childImageSharp {
              fluid(maxWidth: 1180, maxHeight: 555, quality: 92) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          callToAction
          linkURL
        }
        menuFile {
          publicURL
        }
      }
    }
  }
`
